export enum contractKind {
  XCHANGE_ROUTER,
  XCHANGE_DISCOUNT_ROUTER,
  XCHANGE_FACTORY,
  XCHANGE_DISCOUNT_AUTHORITY,
  XCHANGE_OMNI_ROUTER,
  THIRD_PARTY_V2_ROUTER,
  THIRD_PARTY_V2_FACTORY,
  LENDING_POOL_RESERVE,
  LENDING_POOL_V1,
  X7_LENDING_DA,
  LOAN_TERM_001,
  LOAN_TERM_002,
  LOAN_TERM_003,
  X7R_TOKEN,
  X7DAO_TOKEN,
  X7101_TOKEN,
  X7102_TOKEN,
  X7103_TOKEN,
  X7104_TOKEN,
  X7105_TOKEN,
  X7100_LIQ_HUB,
  X7DAO_LIQ_HUB,
  X7R_LIQ_HUB,
  X7100_DA,
  X7DAO_DA,
  X7R_DA,
  X7_TOKEN_TIME_LOCK,
  X7_TOKEN_BURNER,
  X7_ECOSYSTEM_SPLITTER,
  X7_TREASURY_SPLITTER,
  X7_LIQUIDITY_MAXI_NFT,
  X7_DEX_MAXI_NFT,
  X7_BORROWING_MAXI_NFT,
  X7_ECOSYSTEM_MAXI_NFT,
  MULTICALL,
  WRAPPED_NATIVE_TOKEN,
  USDC,
  DAI,
}

export type ContractAddresses = { [key in contractKind]: string }
